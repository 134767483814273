import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { ModelApi } from '../api/ModelApi';
import { EMPTY_ORGANIZATION_MODEL, OrganizationModel } from '../models/OrganizationModel';
import { Model } from '../models/Model';
import { ModelEvaluationApi } from '../api/ModelEvaluationApi';
import { CreateModelEvaluationDto } from '../models/ModelEvaluation';

export class ModelsStore {
  modelEvaluationApi: ModelEvaluationApi;
  modelApi: ModelApi;
  currentOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;

  organizationModels: Array<OrganizationModel> = [];
  defaultOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;
  defaultRagOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;
  supportedModels: Array<Model> = [];

  constructor(modelApi: ModelApi, modelEvaluationApi: ModelEvaluationApi) {
    this.modelApi = modelApi;
    this.modelEvaluationApi = modelEvaluationApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setOrganizationModelsArray(organizationModels: OrganizationModel[]) {
    let defaultModel: OrganizationModel;
    let defaultRagModel: OrganizationModel;

    organizationModels.forEach(model => {
      if (model.isDefault) {
        defaultModel = model;
      }

      if (model.isDefaultFileModel) {
        defaultRagModel = model;
      }
    });

    runInAction(() => {
      this.organizationModels = organizationModels;

      if (defaultModel) {
        this.defaultOrganizationModel = defaultModel;
      }

      if (defaultRagModel) {
        this.defaultRagOrganizationModel = defaultRagModel;
      }
    });
  }

  async createModelEvaluation(modelEvaluation: CreateModelEvaluationDto) {
    const createdModelEvaluation = await this.modelEvaluationApi.create(modelEvaluation);
    return createdModelEvaluation;
  }

  async listModelEvaluation() {
    const evaluationJobs = await this.modelEvaluationApi.listAll();
    return evaluationJobs;
  }

  async showOutPutData(jobData: any) {
    const evaluationJobOutput = await this.modelEvaluationApi.evaluationJobOutput({ ...jobData });
    return evaluationJobOutput;
  }

  async findAllOrganizationModels() {
    const organizationModels = await this.modelApi.findAll();

    this.setOrganizationModelsArray(organizationModels);

    return organizationModels;
  }

  async findAllOrganizationModelsAccessibleByUser() {
    const organizationModels = await this.modelApi.findAllAccessibleByUser();

    this.setOrganizationModelsArray(organizationModels);

    return organizationModels;
  }

  async updateAllOrganizationModels() {
    const mappedModels = this.organizationModels.map(model => {
      return {
        id: model.id,
        isActive: model.isActive,
        isDefault: model.isDefault,
      };
    });

    await this.modelApi.updateAll(mappedModels);
  }

  async removeOne(modelId: string) {
    await this.modelApi.deleteOne(modelId);

    runInAction(() => {
      this.organizationModels = this.organizationModels.filter(model => model.id !== modelId);
    });
  }

  async findSupported() {
    const supportedModels = await this.modelApi.findSupported();

    runInAction(() => {
      this.supportedModels = supportedModels;
    });

    return supportedModels;
  }

  setCurrentOrganizationModel(model: OrganizationModel) {
    runInAction(() => {
      this.currentOrganizationModel = model;
    });
  }

  async updateOrganizationModel(
    updateModelDto: OrganizationModel,
    updateDefaultModel = false,
    updateDefaultRagModel = false
  ) {
    const updatedModel = await this.modelApi.updateOne(updateModelDto);

    const indexOfModelToBeUpdated = this.organizationModels.findIndex(
      organizationModel => organizationModel.id === updatedModel.id
    );

    runInAction(() => {
      if (indexOfModelToBeUpdated < 0) {
        return;
      }

      const newOrganizationModels = [...this.organizationModels];
      newOrganizationModels[indexOfModelToBeUpdated] = updatedModel;

      if (updateDefaultModel) {
        this.updateDefaultModel(updatedModel, newOrganizationModels);
      }

      if (updateDefaultRagModel) {
        this.updateDefaultRagModel(updatedModel, newOrganizationModels);
      }

      this.organizationModels = newOrganizationModels;
    });
  }

  updateDefaultRagModel(updatedModel: OrganizationModel, newOrganizationModels: OrganizationModel[]) {
    const indexOfOldDefaultRagModel = this.organizationModels.findIndex(
      organizationModel => organizationModel.id === this.defaultRagOrganizationModel.id
    );

    if (indexOfOldDefaultRagModel > -1) {
      newOrganizationModels[indexOfOldDefaultRagModel].isDefaultFileModel = false;
    }

    console.log('indexOfOldDefaultRagModel ------------------->> ', indexOfOldDefaultRagModel, updatedModel);

    this.defaultRagOrganizationModel = updatedModel;
  }

  updateDefaultModel(updatedModel: OrganizationModel, newOrganizationModels: OrganizationModel[]) {
    const indexOfOldDefaultModel = this.organizationModels.findIndex(
      organizationModel => organizationModel.id === this.defaultOrganizationModel.id
    );

    if (indexOfOldDefaultModel > -1) {
      newOrganizationModels[indexOfOldDefaultModel].isDefault = false;
    }

    this.defaultOrganizationModel = updatedModel;
  }
}
