export interface ConfigurationModel {
  configName: string;
  url: string;
  token: string;
  sqlServer?: string;
  sqlPort?: string;
  sqlDatabase?: string;
  sqlUsername?: string;
  sqlPassword?: string;
  sqlDriver?: string;
}

export interface SettingsModel {
  configuration: ConfigurationModel;
}

export interface IntegrationModel {
  id: string;
  organizationId: string;
  category: string;
  name: string;
  icon?: string;
  serviceName: string;
  isActive?: boolean;
  description: string;
  tags?: string;
  settings: SettingsModel[];
}

export interface SelectedSqlConnection {
  configName?: string;
  sqlServer: string;
  sqlPort: string;
  sqlDatabase: string;
  sqlUsername: string;
  sqlPassword: string;
}

export const EMPTY_INTEGRATION_MODEL = {
  id: '',
  organizationId: '',
  category: '',
  name: '',
  icon: '',
  serviceName: '',
  isActive: false,
  description: '',
  tags: '',
  settings: [
    {
      configuration: {
        configName: '',
        url: '',
        token: '',
      },
    },
  ],
};

export const SUPPORTED_INTEGRATIONS = {
  SPLUNK: 'Splunk',
  GOOGLE_DRIVE: 'Google Drive',
  SQL_SERVER: 'SQL Server',
};
