import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import FlexRowStart from '../../utils/flex/FlexRowStart';
import IconByAction from '../auditLogs/IconByAction';
import Typography from '@mui/material/Typography';
import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { getInfoDescription } from '../auditLogs/ConversationDetailPage';
import Stack from '@mui/material/Stack';
import Flex from '../../utils/flex/Flex';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

type CompletionDetailPanelProps = {
  closeModal: () => void;
};

const CompletionDetailPanel = ({ closeModal }: CompletionDetailPanelProps) => {
  const {
    localizationStore: { i18next: i18n },
    auditLogsStore: { currentAuditLog },
  } = useStore();

  const [showMessage, setShowMessage] = useState(false);

  if (!currentAuditLog?.additionalInformation) {
    return <p>{i18n.t('adminPortal.auditLog.completion.detailedView.noData')}</p>;
  }

  const {
    source = i18n.t('adminPortal.auditLog.completion.detailedView.unknown'),
    isUserChat = false,
    message = '',
    wasBlocked = false,
    blockedTextIntervals = [],
    wasWarned = false,
    warnedTextIntervals = [],
    wasAnonymized = false,
    anonymizedTextIntervals = [],
    errorMessage = '',
  } = currentAuditLog.additionalInformation;

  const toggleMessage = () => setShowMessage(!showMessage);

  const getIntervalStyle = (type: string) => {
    switch (type) {
      case 'blocked':
        return { color: '#C81E1E', fontSize: '16px', fontWeight: 'bold' };
      case 'warned':
        return { color: '#E3A008', fontSize: '16px', fontWeight: 'bold' };
      default:
        return {};
    }
  };

  const listIntervals = (intervals: any, type: string) => (
    <List style={{ display: 'inline-block', margin: 0, padding: 0 }}>
      {intervals.map((interval: any, index: number) => (
        <ListItem key={index} sx={{ display: 'inline-flex', padding: '0 8px' }}>
          <span style={{ fontSize: '20px', color: '#555' }}>•</span>
          <ListItemText
            primary={interval.Text || `Category: ${interval.Category}`}
            primaryTypographyProps={getIntervalStyle(type)}
            style={{ display: 'inline-block', marginLeft: '8px' }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Stack sx={{ padding: '24px', display: 'flex', height: '100%' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          backgroundColor: COLOR_WHITE,
          height: '100%',
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {i18n.t('adminPortal.auditLog.panels.completion.title') as string}
          </Typography>
          <IconButton size={'small'} onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>

        {errorMessage && (
          <p
            style={{
              color: 'red',
              backgroundColor: '#ffe6e6',
              padding: '10px',
              borderRadius: '5px',
              marginBottom: '12px',
            }}
          >
            <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.errorMessage')}:</strong> {errorMessage}
          </p>
        )}

        {currentAuditLog?.action && (
          <FlexRowStart sx={{ gap: '12px', marginBottom: '12px' }}>
            <IconByAction action={currentAuditLog?.action} />
            <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
              {getInfoDescription(currentAuditLog)}
            </Typography>
          </FlexRowStart>
        )}

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.source')}:</strong>
          <span style={{ marginLeft: '8px' }}>{source}</span>
        </div>

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.isUserChat')}:</strong>
          <span style={{ marginLeft: '8px' }}>{isUserChat ? 'Yes' : 'No'}</span>
        </div>

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.wasBlocked')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            <span>{wasBlocked ? 'Yes' : 'No'}</span>
            {blockedTextIntervals.length > 0 && (
              <div style={{ display: 'flex', marginLeft: '16px', alignItems: 'center' }}>
                <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.blockedTextIntervals')}:</strong>
                {listIntervals(blockedTextIntervals, 'blocked')}
              </div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.wasWarned')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            <span>{wasWarned ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            {warnedTextIntervals.length > 0 && (
              <div style={{ display: 'flex', marginLeft: '16px', alignItems: 'center' }}>
                <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.warnedTextIntervals')}:</strong>
                {listIntervals(warnedTextIntervals, 'warned')}
              </div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.wasAnonymized')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            <span>{wasAnonymized ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            {anonymizedTextIntervals.length > 0 && (
              <div style={{ display: 'flex', marginLeft: '16px', alignItems: 'center' }}>
                <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.anonymizedTextIntervals')}:</strong>
                {listIntervals(anonymizedTextIntervals, 'anonymized')}
              </div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: '12px' }}>
          <p>
            <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.message')}:</strong>
            <button
              onClick={toggleMessage}
              style={{
                marginLeft: '10px',
                fontSize: '16px',
                padding: '0',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                color: '#007BFF',
                border: 'none',
                textDecoration: 'underline',
              }}
            >
              {showMessage
                ? i18n.t('adminPortal.auditLog.completion.detailedView.hideMessage')
                : i18n.t('adminPortal.auditLog.completion.detailedView.showMessage')}
            </button>
          </p>
          {showMessage && <p style={{ backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>{message}</p>}
        </div>
      </Flex>
    </Stack>
  );
};

export default observer(CompletionDetailPanel);
