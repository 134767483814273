import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, Select, AccordionDetails } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Checkbox, MenuItem, Typography, makeStyles } from '@material-ui/core';

import {
  COLOR_BORDER_PRIMARY,
  COLOR_WHITE,
  GRAY_COLORS,
  COLOR_BACKGROUND,
  COLOR_DIVIDER,
  COLOR_TEXT_QUATERNARY,
} from '../../constants/colors';

import MessageInput from '../../components/inputs/MessageInput';
import Button from '../../components/buttons/Button';
import expandMoreIcon from '../../assets/icons/expand-more.svg';
import fileDownLoadIcon from '../../assets/icons/file-download.svg';
import clearChatIcon from '../../assets/icons/clear-chat.svg';
import infoIcon from '../../assets/icons/info.svg';
import ModelSelectionPopover from '../../components/adminPortal/playground/ModelSelectionPopover';
import AddModelDropdown from '../../components/adminPortal/playground/AddModelDropdown';
import { PrimarySwitch } from '../../components/Switch/PrimarySwitch';
import VectorIcon from '../../assets/icons/vector.svg';

const useStyles = makeStyles({
  actionDiv: {
    border: `1px solid ${COLOR_DIVIDER}`,
    borderRadius: '6px',
    padding: '16px',
    width: '100%',
    textAlign: 'center',
    color: GRAY_COLORS.GRAY_700,
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    gap: '30px',
  },
  innerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
  },
  topLeftButtons: {
    alignItems: 'center',
    padding: '7px 16px',
    background: COLOR_WHITE,
    display: 'flex',
    gap: '12px',
    borderRadius: '6px',
    border: `1px solid ${COLOR_DIVIDER}`,
  },
  addedModelsBox: {},
});

const ScreenContainer = styled(Box)`
  display: flex;
  align-items: stretch;
  gap: 16px;
  padding: 24px;
`;

const RightContainer = styled(Box)`
  margin-left: auto;
  background-color: ${COLOR_WHITE};
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  padding: 20px;
`;

const PlayGroundContainer = styled('div')`
  background-color: ${COLOR_WHITE};
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
`;

const MessageInputContainer = styled(Box)`
  background-color: ${COLOR_WHITE};
`;

type CustomRules = {
  PII: string[];
  PCI: string[];
  'Custom Keywords': string[];
  Toxicity: string[];
};

export interface Option {
  id: number;
  providerName: string;
  displayName: string;
  description: string;
}

export interface AgentOption {
  id?: string;
  displayName: string;
}

const PlayGroundPage = () => {
  const elementRef = useRef<HTMLElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>('policy1');
  const [isChecked, setIsChecked] = useState<any>({});
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [addModelExpanded, setAddModelExpanded] = useState<boolean | false>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement | undefined>(null);
  const [isHovered, setIsHovered] = useState(false);

  const customRules: any = {
    PII: ['Account Number', 'Age', 'Date', 'Date of Birth', 'Email'],
    PCI: ['Credit/Debit CVV', 'Credit/Debit Expiry', 'Credit/Debit Number'],
    'Custom Keywords': ['US_Leaders', 'World_leaders'],
    Toxicity: ['Criticism', 'Hate'],
  };

  const handleToggleAll = (key: string) => {
    setIsChecked((curr: any) => {
      return {
        ...curr,
        [key]: {
          isAllChecked: !curr?.[key]?.isAllChecked,
          items: {
            ...(customRules[key].reduce((acc: any, item: string) => {
              acc[item] = !curr?.[key]?.isAllChecked;
              return acc;
            }, {}) ?? {}),
          },
        },
      };
    });
  };

  const handleCheckboxChange = (event: any, key: any, data: any) => {
    setIsChecked((curr: any) => {
      return {
        ...curr,
        [key]: {
          isAllChecked:
            customRules[key]
              .filter((item: any) => item !== data)
              ?.map((i: any) => {
                return curr?.[key]?.items?.[i] ? true : false;
              })
              .every((e: any) => e === true) && event.target.checked
              ? true
              : false,
          items: { ...(curr[key]?.items ?? {}), [data]: !curr[key]?.items?.[data] },
        },
      };
    });
  };

  const onChange = (event: any) => {
    setSelectedFilter(event.target.value);
  };

  const filterValues = [
    {
      value: 'policy1',
      name: 'Policy 1',
    },
    {
      value: 'policy2',
      name: 'Policy 2',
    },
    {
      value: 'policy3',
      name: 'Policy 3',
    },
  ];

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    setPopoverAnchorEl(event?.currentTarget);
  };

  const handleAccordionChange = () => {
    setAddModelExpanded(!addModelExpanded);
  };

  return (
    <ScreenContainer>
      <PlayGroundContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '60%',
          padding: '24px',
          transition: theme =>
            theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <button className={classes.topLeftButtons}>
            <img src={fileDownLoadIcon} alt="Export" />
            <span>Export</span>
          </button>
          <button className={classes.topLeftButtons}>
            <img src={clearChatIcon} alt="Clear" />
            <span>Clear Chat</span>
          </button>
        </Box>
        <Box sx={{ height: '100%' }}>
          <div className={classes.centerDiv}>
            <div className={classes.innerDiv}>
              <h4 style={{ color: COLOR_TEXT_QUATERNARY, fontSize: '18px', fontWeight: '700' }}>AVM Playground</h4>
              <p style={{ color: GRAY_COLORS.GRAY_500, fontSize: '14px' }}>What it can help you with:</p>
            </div>
            <div className={classes.innerDiv}>
              <div className={classes.actionDiv}>Compare AI Models</div>
              <div className={classes.actionDiv}>Run tests using your Policy Rules</div>
              <div className={classes.actionDiv}>Try out models with different settings applied</div>
            </div>
          </div>
        </Box>
        <MessageInputContainer ref={elementRef} sx={{ marginTop: '16px' }}>
          <MessageInput textAreaMinRows={1} textAreaPlaceholder="Write a message" />
        </MessageInputContainer>
      </PlayGroundContainer>
      <RightContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '40%',
          height: 'auto',
          transition: theme =>
            theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ paddingTop: '10px' }}>
              <Button
                id="addModel"
                onClick={event => {
                  handlePopoverOpen(event);
                }}
              >
                <Add sx={{ marginRight: '8px' }} />
                Add Model
              </Button>
            </Box>
            <ModelSelectionPopover
              anchorEl={popoverAnchorEl}
              onClose={() => {
                setPopoverAnchorEl(null);
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px', margin: '20px 0px' }}>
            <p style={{ color: GRAY_COLORS.GRAY_5 }}>Added Models</p>
            <p style={{ textDecoration: 'underline' }}>Remove all</p>
          </Box>
          <Box>
            <Accordion
              expanded={addModelExpanded}
              onChange={handleAccordionChange}
              sx={{ border: `1px solid ${COLOR_DIVIDER}` }}
            >
              <AccordionSummary
                expandIcon={<img src={expandMoreIcon} />}
                onClick={event => {
                  event.stopPropagation();
                }}
                sx={{
                  background: COLOR_WHITE,
                  width: '100%',
                  borderBottom: 'none',
                  borderRadius: '8px 8px 0px 0px',
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    background: GRAY_COLORS.GRAY_1,
                  },
                  margin: '0px',
                }}
              >
                {!addModelExpanded && (
                  <Box
                    sx={{
                      borderRadius: '8px',
                      padding: '2px 16px',
                      fontWeight: '600',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: '48px !important',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <span style={{ width: '50%' }}>Open AI - GPT4</span>
                    {isHovered && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          width: '50%',
                          justifyContent: 'flex-end',
                          marginRight: '14px',
                        }}
                      >
                        <PrimarySwitch
                          checked={toggle}
                          onChange={event => {
                            setToggle(!toggle);
                          }}
                          onClick={event => {
                            event.stopPropagation();
                          }}
                        />
                        <img
                          src={VectorIcon}
                          alt=""
                          style={{ width: '24px', height: '24px' }}
                          onClick={event => {
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    )}
                  </Box>
                )}
                {addModelExpanded && (
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <input
                      onClick={event => {
                        event.stopPropagation();
                      }}
                      placeholder="Name"
                      style={{
                        padding: '12px 16px',
                        background: GRAY_COLORS.GRAY_1,
                        border: `1px solid ${COLOR_DIVIDER}`,
                        width: '50%',
                        borderRadius: '4px',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '14px',
                        width: '50%',
                        justifyContent: 'flex-end',
                        marginRight: '14px',
                      }}
                    >
                      <PrimarySwitch
                        checked={toggle}
                        onChange={event => {
                          setToggle(!toggle);
                        }}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      />
                      <img
                        src={VectorIcon}
                        alt=""
                        style={{ width: '24px', height: '24px' }}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      />
                    </div>
                  </div>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <AddModelDropdown />
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ paddingTop: '20px', margin: '20px 0px' }}>
            <p style={{ margin: '10px 0px' }}>Select Policy Rules</p>
            <Box sx={{ marginTop: '20px' }}>
              <p style={{ color: GRAY_COLORS.GRAY_5, marginBottom: '10px' }}>Company Policy Rules</p>
              <div style={{ width: '100%' }}>
                <Select
                  style={{
                    width: '100%',
                    background: COLOR_BACKGROUND,
                    border: `1px solid ${GRAY_COLORS.GRAY_300}`,
                    borderRadius: '6px',
                    padding: '9px 12px',
                    paddingLeft: '0px',
                  }}
                  labelId={'company-policy-rule'}
                  id={'company-policy-rule'}
                  value={selectedFilter}
                  onChange={onChange}
                >
                  {filterValues?.map((filter, index) => (
                    <MenuItem value={filter.value} key={`filter-${index}`}>
                      <Typography variant={'subtitle2'}>{filter.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          </Box>
          <Box>
            <p style={{ color: GRAY_COLORS.GRAY_5, marginBottom: '10px' }}>Custom</p>
            {Object.keys(customRules).map((key, index) => {
              return (
                <Accordion
                  key={key}
                  style={{ boxShadow: 'none', borderLeft: 'none' }}
                  expanded={expanded === key}
                  onChange={handleChange(key)}
                >
                  <AccordionSummary
                    expandIcon={<img src={expandMoreIcon} />}
                    aria-controls={`panel${index}-content`}
                    id={key}
                    sx={{ border: `1px solid ${COLOR_WHITE}` }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <Checkbox
                        checked={isChecked?.[key]?.isAllChecked}
                        onChange={() => handleToggleAll(key)}
                        color="primary"
                        inputProps={{ 'aria-label': `toggle all ${key}` }}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      />
                      <span>{key}</span>
                      <img src={infoIcon} alt="info" />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ margin: '0px', padding: '0px' }}>
                    {customRules[key as keyof CustomRules].map((data: any, dataIndex: number) => (
                      <div key={dataIndex}>
                        <Checkbox
                          checked={isChecked?.[key]?.items?.[data] ? true : false}
                          onChange={event => handleCheckboxChange(event, key, data)}
                          color="primary"
                          inputProps={{ 'aria-label': `toggle item ${dataIndex + 1}` }}
                        />
                        {data}
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </RightContainer>
    </ScreenContainer>
  );
};

export default observer(PlayGroundPage);
