import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Select, Slider, TextField } from '@mui/material';
import { MenuItem, Typography, makeStyles } from '@material-ui/core';

import { COLOR_WHITE, GRAY_COLORS, COLOR_BACKGROUND, COLOR_DIVIDER, COLOR_PRIMARY } from '../../../constants/colors';

import InfoIcon from '../../../assets/icons/info.svg';
import ApplyToAllIcon from '../../../assets/icons/apply-to-all.svg';
import ReseTIcon from '../../../assets/icons/reset.svg';

const useStyles = makeStyles({
  actionDiv: {
    border: `1px solid ${COLOR_DIVIDER}`,
    borderRadius: '6px',
    padding: '16px',
    width: '100%',
    textAlign: 'center',
    color: GRAY_COLORS.GRAY_700,
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    gap: '30px',
  },
  innerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
  },
  topLeftButtons: {
    alignItems: 'center',
    padding: '7px 16px',
    background: COLOR_WHITE,
    display: 'flex',
    gap: '12px',
    borderRadius: '6px',
    border: `1px solid ${COLOR_DIVIDER}`,
  },
  topRightButton: {
    alignItems: 'center',
    padding: '8px 12px',
    background: COLOR_WHITE,
    display: 'flex',
    gap: '8px',
    borderRadius: '6px',
    border: `1px solid ${COLOR_DIVIDER}`,
    width: '50%',
    justifyContent: 'center',
  },
  addedModelsBox: {},
});

const MessageInputContainer = styled(Box)`
  background-color: ${COLOR_WHITE};
`;

const CustomSlider = styled(Slider)`
  height: 2px;
  margin-bottom: 20px;
  .MuiSlider-track {
    border: none;
  }

  .MuiSlider-rail {
    background-color: ${GRAY_COLORS.GRAY_500};
  }

  .MuiSlider-thumb {
    background-color: ${COLOR_PRIMARY};
    width: 16px;
    height: 12px;
    border-radius: 2px;
  }

  .MuiSlider-valueLabel {
    background-color: transparent;
    top: 45px;
    color: ${GRAY_COLORS.GRAY_500};
  }
`;

const PlayGroundPage = () => {
  const elementRef = useRef<HTMLElement>(null);
  const [selectedModel, setselectedModel] = useState<string>('GPT-4');
  const classes = useStyles();

  const onModelChange = (event: any) => {
    setselectedModel(event.target.value);
  };

  const modelDropdownValues = [
    {
      provideName: 'OpenAI',
      name: 'GPT-3.5k 16',
      value: 'GPT-3.5k 16',
    },
    {
      provideName: 'OpenAI',
      name: 'GPT-4',
      value: 'GPT-4',
    },
    {
      provideName: 'Llama',
      name: 'Llama2 7B File',
      value: 'Llama2 7B File',
    },
  ];

  return (
    <div>
      <div>
        <div style={{ width: '100%' }}>
          <Select
            style={{
              width: '100%',
              background: COLOR_BACKGROUND,
              border: `1px solid ${COLOR_DIVIDER}`,
              borderRadius: '6px',
              paddingLeft: '0px',
            }}
            labelId={'added-models'}
            id={'added-models'}
            value={selectedModel}
            onChange={onModelChange}
          >
            {modelDropdownValues?.map((filter, index) => (
              <MenuItem value={filter.value} key={`filter-${index}`}>
                <Typography variant={'subtitle2'}>
                  Model : {filter.provideName} - {filter.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <MessageInputContainer ref={elementRef} sx={{ marginTop: '16px' }}>
            <TextField
              sx={{
                border: `1px solid ${GRAY_COLORS.GRAY_300}`,
                borderRadius: '6px',
                width: '100%',
              }}
              multiline
              variant="outlined"
              minRows={3}
              placeholder="Description (System Prompts)"
              onKeyDown={({ key, shiftKey }) => {
                if (key === 'Enter' && shiftKey) {
                  return;
                }
              }}
            />
          </MessageInputContainer>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <p style={{ fontSize: '14px', fontWeight: '500' }}>Temperature</p>
          <img src={InfoIcon} alt="" />
        </div>
        <CustomSlider min={0} max={2} step={0.1} valueLabelDisplay={'on'} />
      </div>
      <div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <p style={{ fontSize: '14px', fontWeight: '500' }}>Max Tokens</p>
          <img src={InfoIcon} alt="" />
        </div>
        <CustomSlider min={0} max={4000} step={1} valueLabelDisplay={'on'} />
      </div>
      <div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <p style={{ fontSize: '14px', fontWeight: '500' }}>Accuracy</p>
          <img src={InfoIcon} alt="" />
        </div>
        <CustomSlider min={0} max={2} step={0.1} valueLabelDisplay={'on'} />
      </div>
      <div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <p style={{ fontSize: '14px', fontWeight: '500' }}>Chat Memory</p>
          <img src={InfoIcon} alt="" />
        </div>
        <CustomSlider min={0} max={2} step={0.1} valueLabelDisplay={'on'} />
      </div>
      <Box sx={{ display: 'flex', gap: '12px', padding: '12px 0px' }}>
        <button className={classes.topRightButton}>
          <img src={ReseTIcon} alt="Reset" />
          <span style={{ fontSize: '14px', fontWeight: '600' }}>Reset</span>
        </button>
        <button className={classes.topRightButton}>
          <img src={ApplyToAllIcon} alt="Apply To All" />
          <span style={{ fontSize: '14px', fontWeight: '600' }}>Apply to All</span>
        </button>
      </Box>
    </div>
  );
};

export default observer(PlayGroundPage);
