import { UserApi } from '../api/UserApi';
import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import jwt_decode from 'jwt-decode';

import { LocalStorageKeys } from '../constants/localStorageKeys';

import {
  UpdateUserProfilePictureDto,
  UserFromGetModel,
  UserModelInvite,
  UserModelUpdate,
  UserPasswordUpdate,
  UsersModelUpdate,
  UserTokenDataModel,
} from '../models/UserModel';

import { EmptyUserFromGet, EmptyUserPassword } from '../emptyStates/EmptyUser';

export class UserStore {
  userApi: UserApi;
  loadingUser: boolean = false;
  userData: UserFromGetModel = EmptyUserFromGet;
  users: Array<UserFromGetModel> = [EmptyUserFromGet];
  inactiveUsers: Array<UserFromGetModel> = [EmptyUserFromGet];
  selectedUser: UserFromGetModel = EmptyUserFromGet;

  constructor(userApi: UserApi) {
    this.userApi = userApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  updateLoading(value: boolean) {
    runInAction(() => {
      this.loadingUser = value;
    });
  }

  setSelectedUser(user: UserFromGetModel) {
    runInAction(() => {
      this.selectedUser = user;
    });
  }

  getTokenData() {
    const token = localStorage.getItem(LocalStorageKeys.accessToken) || '';

    return jwt_decode(token) as UserTokenDataModel;
  }

  async getUserInformation() {
    let userData = await this.userApi.getUserData();
    localStorage.setItem(LocalStorageKeys.userRole, userData.role);
    localStorage.setItem(LocalStorageKeys.userData, JSON.stringify(userData));

    runInAction(() => {
      this.userData = userData;
    });

    return userData;
  }

  async getUserSummaryById(id: string) {
    const summaryDetails = await this.userApi.getUserSummaryById(id);
    return summaryDetails;
  }

  async getUserDataById(id: string) {
    const selectedUser = await this.userApi.getUserDataById(id);
    const summaryDetails = await this.getUserSummaryById(id);

    runInAction(() => {
      this.selectedUser = { ...selectedUser, summaryDetails };
    });

    return { ...selectedUser, summaryDetails };
  }

  async inviteUserToOrganization(user: UserModelInvite) {
    const invite = await this.userApi.inviteUserToOrganization(user);
    await this.getUsersByUserOrganization();
    return invite;
  }

  async inviteUsersToOrganization({
    users,
    organizationId,
  }: {
    users: UserModelInvite[];
    organizationId: string | undefined;
  }) {
    const invite = await this.userApi.inviteUsersToOrganization({ users, organizationId });
    await this.getUsersByUserOrganization();
    return invite;
  }

  async getUsersByUserOrganization() {
    const users = await this.userApi.getUsersByUserOrganization();
    await this.attachSummaryDetails(users);

    runInAction(() => {
      this.users = users;
    });

    return users;
  }

  async getInactiveUsersByUserOrganization() {
    const users = await this.userApi.getInactiveUsersByUserOrganization();
    await this.attachSummaryDetails(users);

    runInAction(() => {
      this.inactiveUsers = users;
    });
    return users;
  }

  async attachSummaryDetails(users: UserFromGetModel[]) {
    return Promise.all(
      users.map(async item => {
        const summaryDetails = await this.getUserSummaryById(item.id);
        item.totalChats = summaryDetails?.totalChats || 0;
        return item;
      })
    );
  }

  async filterUserByRoleAndStatus(role: string) {
    const users = await this.userApi.filterUserByRoleAndStatus(role, false);
    const inactiveUsers = await this.userApi.filterUserByRoleAndStatus(role, true);
    await this.attachSummaryDetails(users);
    await this.attachSummaryDetails(inactiveUsers);

    runInAction(() => {
      this.users = users;
      this.inactiveUsers = inactiveUsers;
    });
  }

  async editUser(data: UsersModelUpdate, id: string) {
    const response = await this.userApi.editUser(data, id);
    await this.getUsersByUserOrganization();
    await this.getInactiveUsersByUserOrganization();
    runInAction(() => {
      this.selectedUser = response;
    });
    return response;
  }

  async updateUserInformation(data: UserModelUpdate) {
    const userData = await this.userApi.updateUserData(data);

    runInAction(() => {
      this.userData = userData;
    });
  }
  async deleteUser(id: string) {
    await this.userApi.deleteUser(id);
    await this.getUsersByUserOrganization();
    await this.getInactiveUsersByUserOrganization();
    runInAction(() => {
      this.selectedUser = { ...this.selectedUser, archived: true };
    });
  }

  async enableUser(id: string) {
    await this.userApi.enableUser(id);
    await this.getUsersByUserOrganization();
    await this.getInactiveUsersByUserOrganization();
    runInAction(() => {
      this.selectedUser = { ...this.selectedUser, archived: false };
    });
  }

  async updateUserPassword(data: UserPasswordUpdate) {
    try {
      await this.userApi.updateUserPassword(data);
      return EmptyUserPassword;
    } catch (e: any) {
      const message = e?.response?.data?.Errors[0].Details[0].ErrorMessage;

      if (message && message === 'PasswordMismatch') {
        return {
          ...EmptyUserPassword,
          oldPassword: 'errors.managePassword.oldPassword.message',
        };
      } else {
        return {
          ...EmptyUserPassword,
          newPassword: 'errors.managePassword.newPassword.message',
        };
      }
    }
  }

  async updateUserProfilePictureData(id: string, data: UpdateUserProfilePictureDto) {
    return this.userApi.updateUserProfilePictureData(id, data);
  }
}
